<template>
  <div
    class="relative flex w-full justify-center p-1 md:z-30 md:mb-0"
    :class="[backgroundColorClass, textColorClass]"
  >
    <div
      class="relative flex w-full whitespace-nowrap text-2xs font-semibold uppercase motion-safe:overflow-x-hidden lg:hidden"
    >
      <div class="flex motion-safe:animate-marquee sm:w-full sm:animate-none">
        <span
          v-for="ribbonText in ribbonTexts"
          :key="ribbonText"
          class="mx-auto px-4"
        >
          {{ ribbonText }}
        </span>
      </div>
      <div class="absolute top-0 flex motion-safe:animate-marquee2 sm:hidden">
        <span
          v-for="ribbonText in ribbonTexts"
          :key="ribbonText"
          class="mx-auto px-4"
        >
          {{ ribbonText }}
        </span>
      </div>
    </div>

    <div class="hidden w-full max-w-7xl justify-between px-4 lg:flex">
      <div
        v-for="(ribbonTextsSection, i) in ribbonTextSectionsDesktop"
        :key="i"
        class="flex flex-row content-start"
      >
        <span
          v-for="(ribbonText, index) in ribbonTextsSection"
          :key="index + '-' + ribbonText"
          class="flex items-center"
        >
          <a v-if="ribbonText.includes('@')" :href="`mailto:${ribbonText}`">
            {{ ribbonText }}
          </a>
          <span v-else>{{ ribbonText }}</span>
          <span
            v-if="index !== ribbonTextsSection.length - 1"
            class="mx-2 opacity-50"
            >|</span
          >
        </span>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
const { t, tm } = useI18n();

defineProps({
  backgroundColorClass: {
    type: String,
    default: 'bg-black',
  },
  textColorClass: {
    type: String,
    default: 'text-white',
  },
});

const ribbonTexts = computed(() =>
  Object.keys(tm('ribbon')).map((key) => t(`ribbon.${key}`))
);

const ribbonTextSectionsDesktop = computed(() => [
  ribbonTexts.value,
  [t('support.email'), t('support.phone')],
]);
</script>
