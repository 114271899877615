<!-- eslint-disable vue/no-v-html -->
<template>
  <div class="relative">
    <button
      class="absolute left-0 top-0 z-10 p-6"
      size="sm"
      color="secondary"
      @click="emitToggleChat"
    >
      <DynamicPictogram name="arrow" />
    </button>

    <div class="flex flex-col items-center">
      <h3 class="w-full py-4 text-center text-xl text-gray-900">
        {{ t('chat.chat_header') }}
      </h3>

      <div class="flex min-h-80 w-full border-t p-4">
        <div
          class="chat-bubble w-full self-end rounded-t-2xl rounded-bl rounded-br-2xl bg-gray-200 p-4"
        >
          <p
            v-for="(row, index) in tm('chat.chat_message')"
            :key="index"
            v-html="rt(row)"
          ></p>
        </div>
      </div>

      <div
        class="inline-flex w-full flex-col items-start justify-start overflow-hidden"
      >
        <div class="h-px self-stretch bg-[#110f0e]/10"></div>
        <div
          class="inline-flex items-end justify-center gap-2 self-stretch px-3 py-2"
        >
          <div
            class="flex h-[40px] shrink grow basis-0 items-end justify-start gap-2.5 overflow-hidden rounded-[10px] bg-[#110f0e]/5 px-4 pb-[9px] pt-2 text-[15px] font-medium leading-[23px]"
          ></div>
          <button
            disabled
            class="relative flex h-[40px] flex-col justify-center overflow-hidden rounded-[10px] bg-[#110f0e]/5 p-2"
          >
            <DynamicPictogram name="arrow" size="md" class="block rotate-90" />
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
const { t, tm, rt } = useI18n();
const emit = defineEmits(['toggle-chat']);

const emitToggleChat = () => {
  emit('toggle-chat');
};
</script>

<style scoped>
.chat-bubble {
  animation: message 0.2s ease-out 0s forwards;
  transform-origin: 0 100%;
  transform: scale(0);
}

@keyframes message {
  0% {
    max-height: 100vmax;
  }
  60% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
    max-height: 100vmax;
    overflow: visible;
    padding-top: 1rem;
  }
}
</style>
