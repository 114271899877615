<template>
  <transition name="fade">
    <div
      v-if="showModal"
      ref="dialogRef"
      class="pointer-events-none fixed bottom-0 right-0 z-10 flex size-full justify-end p-4"
    >
      <div
        class="pointer-events-auto relative w-full self-end overflow-scroll rounded-xl bg-white shadow-2xl md:w-[360px]"
        :style="`margin-bottom: ${stickyButtonHeight}px; max-height: calc(100% - ${stickyButtonHeight + 130}px);`"
      >
        <button
          id="chat_close"
          class="absolute right-0 top-0 z-60 p-6"
          @click="toggleModal"
        >
          <DynamicPictogram name="times" />
        </button>

        <template v-if="showChat">
          <FakeChatChat @toggle-chat="toggleChat" />
        </template>

        <template v-else>
          <FakeChatMenu @toggle-modal="toggleModal" @toggle-chat="toggleChat" />
        </template>
      </div>
    </div>
  </transition>

  <div class="fixed bottom-0 right-0 z-10 p-4">
    <FakeChatOpenButton
      :sticky-button-height="stickyButtonHeight"
      :show-modal="showModal"
      @toggle-modal="toggleModal"
    />
  </div>
</template>

<script setup lang="ts">
const { isSm } = useTailwindBreakpoints();

const stickyButtonVisibleOnMobile = ref(false);
const stickyButtonHeight = ref(0);

const updateStickyButtonStatus = async () => {
  await nextTick();
  stickyButtonVisibleOnMobile.value = document.body.classList.contains(
    'sticky-button-visible-on-mobile'
  );
  stickyButtonHeight.value =
    document.getElementById('sticky-button')?.clientHeight || 0;
};

watch([isSm, stickyButtonVisibleOnMobile], () => {
  stickyButtonHeight.value =
    document.getElementById('sticky-button')?.clientHeight || 0;
});

let observer: MutationObserver | null = null;
onMounted(() => {
  updateStickyButtonStatus();
  observer = new MutationObserver(() => updateStickyButtonStatus());
  observer.observe(document.body, {
    attributes: true,
    attributeFilter: ['class'],
  });
});
onUnmounted(() => {
  observer?.disconnect();
});

const showModal = ref(false);
const toggleModal = () => {
  showModal.value = !showModal.value;
};

const showChat = ref(false);
const toggleChat = () => {
  showChat.value = !showChat.value;
};
</script>
