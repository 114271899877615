<template>
  <div id="header" class="sticky top-0 z-20">
    <HeaderRibbon v-if="hasShop" :background-color-class="ribbonColorClass" />
    <header class="sticky top-0 z-50" :class="ribbonColorClass">
      <div>
        <div
          id="header-bar"
          class="z-50 rounded-t-xl bg-background-extra-light shadow-md md:shadow-[0px_0px_0px_1px_rgba(0,0,0,0.087)]"
          :class="{ 'nav-menu-open': isNavMenuOpen }"
        >
          <div
            id="header-grid"
            class="mx-auto max-w-[1440px] lg:gap-8 lg:pl-5 lg:pr-1 xl:gap-12"
          >
            <HeaderMenuMobileOpenButton />

            <HeaderLogo />

            <HeaderMenu />

            <div
              v-if="hasShop"
              class="header-search flex items-center justify-center"
            >
              <SearchForm @search-focus="hideSpeakToVet = $event" />
            </div>

            <div v-if="hasShop" class="ml-auto flex gap-1 space-x-3">
              <ClientOnly>
                <template #fallback>
                  <!-- this will be rendered on server side -->
                  <div
                    v-if="isAccountsEnabled && hasShop"
                    class="ml-2 flex cursor-pointer flex-col items-center space-y-1 self-center justify-self-center"
                  >
                    <StaticPictogram
                      size="lg"
                      name="account"
                      class="min-h-[24px] min-w-[24px]"
                    />
                    <span class="text-2xs">
                      {{ $t('account.account') }}
                    </span>
                  </div>
                </template>

                <NuxtLink
                  v-if="isAccountsEnabled && hasShop"
                  :to="accountUrl"
                  class="ml-2 flex flex-col items-center space-y-1 self-center justify-self-center overflow-hidden"
                  data-test-id="account-button"
                >
                  <StaticPictogram
                    size="lg"
                    :name="!isLoggedIn ? 'account' : 'account-filled'"
                    class="min-h-[24px] min-w-[24px]"
                  />
                  <span class="text-2xs">
                    <template
                      v-if="profile && profile.firstName && isLoggedIn"
                      >{{ profile.firstName }}</template
                    >
                    <template v-else>{{ $t('account.account') }}</template>
                  </span>
                </NuxtLink>
              </ClientOnly>

              <button
                v-if="hasShop"
                data-test-id="cart-button"
                :aria-label="$t('cart.show_cart')"
                class="size-13 flex flex-col items-center space-y-1 self-center justify-self-end pr-5"
                @click="toggleSidebar()"
              >
                <CartPictogram
                  :animate-on-update="true"
                  :cart-amount="itemCount"
                  size-class="w-6 h-6"
                  cart-amount-class="text-white text-xs md:text-base"
                />
                <span class="text-2xs">
                  {{ $t('cart.cart') }}
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </header>
    <div class="block lg:hidden">
      <HeaderMenuMobile />
    </div>
    <transition name="fade">
      <div
        v-show="showSearchSuggestions || isNavMenuOpen"
        class="fixed inset-0 z-20 size-full bg-gray-900/50"
      />
    </transition>
  </div>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { useCartStore } from '~/stores/cart';
import { useSearchStore } from '~/stores/search';
import { useSidebarStore } from '~/stores/sidebar';
import { useAuthStore } from '~/stores/auth';
import { useGlobalMenuStore } from '~/stores/headerMenu';

const props = defineProps({
  ribbonColorClass: {
    type: String,
    default: 'bg-black',
  },
});

const cartStore = useCartStore();
const searchStore = useSearchStore();

const headerMenu = useGlobalMenuStore();
const { isNavMenuOpen } = storeToRefs(headerMenu);
const sidebarStore = useSidebarStore();
const authStore = useAuthStore();
const { $growthbook } = useNuxtApp();

const localePath = useLocalePath();

const { enabledTheme } = useRuntimeConfig().public;

const ribbonColorClass = computed(() => {
  if (!enabledTheme) {
    return props.ribbonColorClass;
  }

  switch (enabledTheme) {
    case 'christmas':
      return 'bg-supporting-dark-4';
    default:
      return props.ribbonColorClass;
  }
});

const hasShop = computed(() => useBaseCountryConfig().hasShop);

const { isAccountsEnabled } = useRuntimeConfig().public.featureFlags;

const { isLoggedIn, profile } = storeToRefs(authStore);

const hideSpeakToVet = ref(false);

const itemCount = computed(() => cartStore.getOptimisticItemCount);

const showSearchSuggestions = computed(() => searchStore.showSearchSuggestions);

const cartUpsellFlagEnabled = ref($growthbook?.isOn('cart-upsell'));

const hasLoggedInBefore = (() => {
  let result = false;

  if (process.client) {
    // We might not always go to a route that can set this value
    // So for safety, we set it in here too
    try {
      if (isLoggedIn.value) {
        localStorage.setItem('hasLoggedInBefore', 'hasLoggedInBefore');
      }
      result = !!localStorage.getItem('hasLoggedInBefore');
    } catch {
      console.info('Localstorage not available');
    }
  }

  return result;
})();

const shopDashboardPath = useDashboardLandingPage();

const authUrl = computed(() => {
  if (hasLoggedInBefore) {
    return authStore.getLoginUrl({
      redirectPath: shopDashboardPath,
    });
  }

  return localePath({ name: 'shop-login' });
});

const accountUrl = computed(() =>
  isLoggedIn.value ? shopDashboardPath : authUrl.value
);
const toggleSidebar = async (open?: boolean) => {
  if (cartUpsellFlagEnabled.value) {
    await cartStore.getCartRecommendations();
  }

  sidebarStore.toggleSidebar(open);
};

if (isAccountsEnabled && !profile.value?.firstName) {
  authStore.getProfile();
}
</script>

<style type="postcss">
/* When this was written tailwind did not have a sane support for grid area */
@media (max-width: 768px) {
  .header-search {
    grid-area: sub-header;
  }
}
#header-grid {
  display: grid;
  grid-template-columns: max-content max-content auto max-content;
  grid-template-rows: 1fr;
  height: 60px;
}

@media (max-width: 768px) {
  #header-grid {
    grid-area: sub-header;
    display: grid;
    grid-template-columns: 60px 3fr 4fr;
    grid-template-rows: 60px auto;
    gap: 0px 0px;
    height: unset;
    grid-template-areas:
      '. . .'
      'sub-header sub-header sub-header';
  }
}
</style>
