<template>
  <div class="overflow-hidden">
    <h3 v-if="recommendations.length > 0" class="ml-4 text-lg">
      {{ $t('cart_recommendations.cart_recommendations_title') }}
    </h3>
    <section
      class="-mx-4 grid grid-cols-2 gap-2 bg-background-extra-light p-4 px-8 xs:grid-cols-2 md:mx-0 md:grid-cols-2 md:gap-4 md:p-4 lg:grid-cols-2"
    >
      <ProductCard
        v-for="(cartRecommendation, index) in recommendations"
        :id="parseInt(cartRecommendation.id)"
        :key="parseInt(cartRecommendation.id)"
        class="rounded-xl border border-[#110F0E1F]"
        :slug="removeSlashes(cartRecommendation.path)"
        :title="cartRecommendation.title"
        :regular-price="cartRecommendation.base_price"
        :special-price="cartRecommendation.sale_price || ''"
        :is-cheapest-variant-price="
          cartRecommendation.is_cheapest_variant_price
        "
        :horizontal="false"
        :image="cartRecommendation.image || cartRecommendation.parentImage"
        :brand="{
          entityId: +cartRecommendation.brandId,
          name: cartRecommendation.brandName,
          slug: removeSlashes(cartRecommendation.brandPath),
        }"
        :category-ids="
          cartRecommendation.categoriesId?.map((id: string) => +id)
        "
        :tracking-data="{
          product: cartRecommendation.trackingData,
          position: index + 1,
          extraFields: { brand: cartRecommendation.brandName },
          list: 'Sidebar Cart Recommendations',
        }"
        :options="[]"
        :variants="[cartRecommendation.variants[0]]"
        :enable-variant-select="false"
        :is-cart-recommendation="true"
        :campaign="cartRecommendation.campaign"
        :campaign-badge-should-overflow="false"
        campaign-badge-size="sm"
        :is-available="cartRecommendation.is_available"
        :product-click-callback="
          () => sendLoop54ClickEvent(+cartRecommendation.id)
        "
        :add-to-cart-callback="() => addToCartCallback(+cartRecommendation.id)"
      />
    </section>
  </div>
</template>

<script setup lang="ts">
import type { NormalizedLoop54Product } from '#root/shared/types/loop54-types';
import { removeSlashes } from '#root/shared/utils/types/string-utils';

const props = defineProps({
  cartRecommendations: {
    required: true,
    type: Array<NormalizedLoop54Product>,
    default: () => [],
  },
});

const cartStore = useCartStore();
const searchStore = useSearchStore();
const sidebarStore = useSidebarStore();

const recommendations = computed(() => props.cartRecommendations.slice(0, 2));

const sendLoop54ClickEvent = (cartRecommendationsId: number) => {
  sidebarStore.toggleSidebar(false);
  searchStore.sendLoop54ClickEvent(cartRecommendationsId);
};
const sendLoop54AddToCartEvent = (cartRecommendationsId: number) =>
  searchStore.sendLoop54AddToCartEvent(cartRecommendationsId);

const addToCartCallback = (cartRecommendationId: number) => {
  sendLoop54AddToCartEvent(cartRecommendationId);

  if (recommendations.value.length === 1) {
    // Fetch new recommendations when all current ones are added to cart
    cartStore.getCartRecommendations();
  } else {
    cartStore.removeCartRecommendation(cartRecommendationId);
  }
};
</script>
