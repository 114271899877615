<template>
  <div class="flex flex-col items-center gap-4 p-5">
    <div
      class="mt-4 inline-flex size-20 items-center justify-center gap-2 rounded-full border"
    >
      <StaticPictogram name="service-list-vet" size="3xl" />
    </div>
    <h3 class="w-full text-center text-2xl leading-8 text-black/87">
      {{ t('chat.hey') }}
    </h3>
    <div class="w-full text-center text-base font-medium leading-6">
      {{ t('chat.hey_preamble') }}
    </div>
    <div
      class="flex w-full flex-col items-center overflow-hidden rounded-xl border border-gray-200"
    >
      <template v-for="link in links" :key="link.url">
        <NuxtLink
          v-if="link.type === 'link'"
          :id="link.id"
          class="flex w-full justify-between gap-2 border-b border-gray-200 px-4 py-5 last:border-b-0"
          :to="t(link.url)"
          @click="$emit('toggle-modal')"
        >
          <span class="flex text-black/87">{{ t(link.translation) }}</span>
          <StaticPictogram name="chevron-right" size="lg" />
        </NuxtLink>
        <button
          v-else-if="link.type === 'chat'"
          :id="link.id"
          class="flex w-full justify-between gap-2 border-b border-gray-200 px-4 py-5 last:border-b-0"
          @click="$emit('toggle-chat')"
        >
          <span class="flex text-black/87">{{ t(link.translation) }}</span>
          <StaticPictogram name="chevron-right" size="lg" />
        </button>
      </template>
    </div>
  </div>
</template>

<script setup lang="ts">
import { defineEmits } from 'vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

defineEmits<{
  (e: 'toggle-modal'): void;
  (e: 'toggle-chat'): void;
}>();

const links = [
  { id: 'chat_chat', translation: 'chat.link_chat', type: 'chat' },
  {
    id: 'chat_bonuscard',
    translation: 'chat.link_bonuscard',
    url: 'chat.link_bonuscard_url',
    type: 'link',
  },
  {
    id: 'chat_return',
    translation: 'chat.link_return',
    url: 'chat.link_return_url',
    type: 'link',
  },
  {
    id: 'chat_customer_service',
    translation: 'chat.link_customer_service',
    url: 'chat.link_customer_service_url',
    type: 'link',
  },
];
</script>
