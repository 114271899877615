<template>
  <button
    v-if="!showModal"
    id="chat_open"
    class="inline-flex h-12 items-center justify-end gap-2 rounded-xl bg-blue-900 px-3 py-2 shadow-lg"
    :style="`margin-bottom: ${stickyButtonHeight}px`"
    @click="handleToggleModal"
  >
    <div class="text-center text-base font-semibold leading-6 text-white">
      {{ t('chat.help') }}
    </div>
    <div class="relative size-6 overflow-hidden">
      <DynamicPictogram name="question" size="lg" color="white" />
    </div>
  </button>
</template>

<script setup lang="ts">
const { t } = useI18n();

defineProps<{
  stickyButtonHeight: number;
  showModal: boolean;
}>();

const emit = defineEmits(['toggle-modal']);

const handleToggleModal = () => {
  emit('toggle-modal');
};
</script>
